.gallery-card {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .gallery-img {
    height: 400px;
    width: 400px;
    border-radius: 8px;
    object-fit: cover;
    cursor: pointer;
  }
  