@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes parpadeo {
    0% { 
        opacity: 1; 
        filter: blur(4px);
    }
    10% { 
        opacity: 0;
        /* filter: blur(20px); */
    }
    50% { 
        opacity: 0;
        filter: blur(7px);
    }
    100% { 
        opacity: 1; 
        filter: blur(4px);
    }

}

@keyframes balanceo {
    0% {
      transform: translateY(0); /* Posición inicial */
    }
    50% {
      transform: translateY(-10px); /* Punto intermedio */
    }
    100% {
      transform: translateY(0); /* Posición final */
    }
}
*{
  font-family: "Plus Jakarta Sans", Sans-serif;
}

.glass{
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

#image_poligon{
  /* position: absolute; */
  background-color: #d3d0c9;
  background-image: url("../imagenes/perro7.jpg");
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  clip-path: polygon(100% 0, 100% 70%, 70% 100%, 0 100%, 0 30%, 30% 0);
  filter: blur(3px);
}

#sub_poligon{
  /* background-color: rgba(172, 138, 141, 0.4); */
  background-color: rgba(48, 40, 124, 0.5);
  clip-path: polygon(100% 0, 100% 70%, 70% 100%, 0 100%, 0 30%, 30% 0);
}

#subimg{
  background-color: rgba(48, 40, 124, 0.5);
}

#servicios{
  background-color: #d3d0c9;
  background-image: url("../imagenes/perro3.jpg");
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

.backgroundimg{
  background-color: #d3d0c9;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}